html,
body {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100%;
  height: 100%;
  display: flex;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0;
}

.app {
  font-size: calc(10px + 2vmin);
  color: black;
  display: flex;
  flex: 1;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.animate__fadeIn {
  animation: fadeIn 500ms ease-in forwards;
}

.animate__fadeOut {
  animation: fadeOut 500ms ease-out forwards;
}

/** #toastLoading: same as components/Loading  */
.Toastify:has(#toastLoading) {
  position: fixed;
  width: 100%;
  height: 100%;
}

div .Toastify__toast-container--top-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

div .Toastify__toast-body {
  flex-direction: column;
  display: flex;
  text-align: center;
}

div .Toastify__toast-icon {
  margin-bottom: 12px;
}

.MuiPickersDay-today {
  border: 1px solid rgba(0, 0, 0, 0.87) !important;
}
.Mui-selected {
  background-color: rgba(234, 95, 0, 1) !important;
}
