@import "~/src/styles/vars.less";

@dotSize: 8px;
@iconSize: 28px;

@center: (@iconSize - @dotSize) / 2;

@keyframes loadingOrange {
  0% {
    transform: scale(1);
    translate: 0px;
    z-index: 2;
  }
  25% {
    transform: scale(1.5);
    translate: @center;
    z-index: 2;
  }
  50% {
    transform: scale(1);
    translate: @iconSize - @dotSize;
    z-index: 2;
  }
  75% {
    transform: scale(0.75);
    translate: @center;
    z-index: 1;
  }
  100% {
    transform: scale(1);
    translate: 0px;
    z-index: 1;
  }
}

@negCenter: -(@iconSize - @dotSize) / 2;
@keyframes loadingBlue {
  0% {
    transform: scale(1);
    translate: 0px;
    z-index: 1;
  }
  25% {
    transform: scale(0.75);
    translate: @negCenter;
    z-index: 1;
  }
  50% {
    transform: scale(1);
    translate: -(@iconSize - @dotSize);
    z-index: 2;
  }
  75% {
    transform: scale(1.5);
    translate: @negCenter;
    z-index: 2;
  }
  100% {
    transform: scale(1);
    translate: 0px;
    z-index: 2;
  }
}

.loadingIcon {
  padding: 2px;
  width: @iconSize;
  height: @iconSize;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dot {
  display: inline-block;
  height: @dotSize;
  width: @dotSize;
  border-radius: @dotSize;
  animation-timing-function: ease;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.oDot {
  animation-name: loadingOrange;
  background-color: @PRIMARY;
}

.bDot {
  animation-name: loadingBlue;
  background-color: @BLUE;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 32px;
}

.desc {
  margin-top: 4px;
  font-size: 14px;
  color: @TEXT_PRIMARY;
}
