@import '../../styles/vars.less';

.dialog {
  display: grid;
  position: fixed;
  top: 50%;
  left: 50%;
}

.dialog_mask {
  flex: 1;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.dialog_title {
  font-weight: 500;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 12px;
}

.dialog_title_h5 {
  font-size: 18px;
  line-height: 22px;
}

.dialog_title_pc {
  font-size: 20px;
  line-height: 24px;
}

.dialog_container {
  padding: 20px;
  border-radius: 4px;
  background-color: #FFFFFF;
  justify-self: center;
  align-self: center;
  text-align: center;
  z-index: 2;
  position: relative;
  left: -50%;
  top: -50%;
  box-sizing: border-box;
  border-radius: 16px;
}

.dialog_container_pc {
  width: 400px;
}

.dialog_container_h5 {
  width: 315px;
}

.dialog_content {
  color: rgba(0, 0, 0, 0.54);
  line-height: 16px;
  font-size: 14px;
}

.dialog_confirm_btn {
  flex: 1;
  width: 100%;
  height: 100%;
  background-color: #EA5F00;
  padding: 12px;
  margin-top: 24px;
  color: #FFFFFF;
  border-radius: 4px;
  border-width: 0px;
  font-size: 14px,
}

.dialog_cancel_btn {
  flex: 1;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 1px solid #EA5F00;
  padding: 12px;
  margin-top: 12px;
  color: #EA5F00;
  border-radius: 4px;
  font-size: 14px,
}

.dialog_btn_container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-top: 24px;

  .dialog_confirm_btn,
  .dialog_cancel_btn {
    margin-top: 0;
    width: unset;
    height: unset;
  }

  .dialog_cancel_btn {
    margin-right: 12px;
  }
}

@media (min-device-width: @screen-md) {
  .dialog_confirm_btn {
    cursor: pointer;
  }

  .dialog_cancel_btn {
    cursor: pointer;
  }
}