@import "~/src/styles/vars.less";

.loading {
  background-color: @WHITE;
  color: @TEXT_PRIMARY;
  width: 164px;
  height: 88px;
  padding-bottom: 12px;
  border-radius: 8px;
  text-align: center;
  margin: 0 auto;
}